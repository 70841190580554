<template>
  <div style="max-width: 1196px; margin: 0 auto;" @click="clickToHidePopup">
    <a-row class="admin-product-inventory__header">
      <!-- header -->
      <a-col :span="24">
        <a-row style="margin-bottom: 20px;">
          <a-col :span="18">
            <span class="admin-product-inventory__header__title" style="float: left;">{{
              $t("Admins.AdminInventory.productHistory")
            }}</span>
          </a-col>
          <a-col :span="6">
            <a-button class="admin-product-inventory__header__back-btn" style="float: right; margin-top: 10px;"
              @click="clickBack">
              {{ $t("Admins.AdminInventory.back") }}
            </a-button>
          </a-col>
        </a-row>
        <a-row class="admin-product-inventory__header__product-info">
          <a-col :span="10">
            <a-row class="admin-product-inventory__header__product-info-name">{{
              productName ? productName : ""
            }}</a-row>
            <a-row class="admin-product-inventory__header__product-info-other">
              <a-col :span="5" style="text-align: right;">
                {{ $t("Admins.AdminInventory.sku") }}
              </a-col>
              <a-col :span="19">
                <div
                  class="admin-product-inventory__header__product-info-bg admin-product-inventory__header__product-info-other-left">
                  {{ productSKU ? productSKU : "" }}
                </div>
              </a-col>
            </a-row>
            <a-row class="admin-product-inventory__header__product-info-other">
              <a-col :span="5" style="text-align: right;">
                {{ $t("Admins.AdminInventory.msrp") }}
              </a-col>
              <a-col :span="19">
                <div
                  class="admin-product-inventory__header__product-info-bg admin-product-inventory__header__product-info-other-left">
                  {{ product ? priceFormat(product.msrpUsd) : "" }}
                </div>
              </a-col>
            </a-row>
            <a-row class="admin-product-inventory__header__product-info-other">
              <a-col :span="5" style="text-align: right;">
                {{ $t("Admins.AdminInventory.size") }}
              </a-col>
              <a-col :span="19">
                <div
                  class="admin-product-inventory__header__product-info-bg admin-product-inventory__header__product-info-other-left">
                  {{
                    product && product.sizeUnits ? `${product.size} ${product.sizeUnits.id}` : ""
                  }}
                </div>
              </a-col>
            </a-row>
            <a-row class="admin-product-inventory__header__product-info-other">
              <a-col :span="5" style="text-align: right;">
                {{ $t("Admins.AdminInventory.origin") }}
              </a-col>
              <a-col :span="19">
                <div
                  class="admin-product-inventory__header__product-info-bg admin-product-inventory__header__product-info-other-left">
                  {{
                    product && product.countryOfOrigin
                      ? countryName(product.countryOfOrigin.id)
                      : ""
                  }}
                </div>
              </a-col>
            </a-row>
            <a-row class="admin-product-inventory__header__product-info-other">
              <a-col :span="5" style="text-align: right;">
                {{ $t("Admins.AdminInventory.category") }}
              </a-col>
              <a-col :span="19">
                <div
                  class="admin-product-inventory__header__product-info-bg admin-product-inventory__header__product-info-other-left">
                  {{
                    product && product.category
                      ? `${product.category.name} > ${product.subcategory.name}`
                      : ""
                  }}
                </div>
              </a-col>
            </a-row>
          </a-col>
          <a-col :span="7" style="text-align: center;">
            <img :src="product && product.images && product.images.length > 0
              ? product.images[0].asset.url
              : ''
              " :alt="$t('Admins.AdminInventory.productImage')"
              style="width: 220px; background-color: #F6F9FD; max-height: 400px;" />
          </a-col>
          <a-col :span="7" style="padding-left: 20px;">
            <div class="admin-product-inventory__header__product-info-bg" style="margin-bottom: 8px;">
              <div class="admin-product-inventory__header__product-info-number">
                {{ totalQunatity }}
              </div>
              <div class="admin-product-inventory__header__product-info-number-desc">
                {{ $t("Admins.AdminInventory.total") }}
              </div>
            </div>
            <div class="admin-product-inventory__header__product-info-bg" style="margin-bottom: 8px;">
              <div class="admin-product-inventory__header__product-info-number">
                {{ allocatedQuantity }}
              </div>
              <div class="admin-product-inventory__header__product-info-number-desc">
                {{ $t("Admins.AdminInventory.allocated") }}
              </div>
            </div>
            <div class="admin-product-inventory__header__product-info-bg">
              <div class="admin-product-inventory__header__product-info-number">
                {{ availableQuantity }}
              </div>
              <div class="admin-product-inventory__header__product-info-number-desc">
                {{ $t("Admins.AdminInventory.available") }}
              </div>
            </div>
          </a-col>
        </a-row>
      </a-col>
    </a-row>
    <a-row id="middleOperationLayer" style="margin-top: 6px; margin-bottom: 10px; max-width: 1196px;">
      <a-button class="admin-product-inventory__header__download-btn" style="float: left;" @click="clickToDownloadData">
        {{ $t("Admins.AdminInventory.csv") }}
        <a-icon type="download" />
      </a-button>
      <a-popconfirm v-if="isNeedAdjustment" id="adjustmentActionPopup" placement="bottom" :visible="adjustmentVisible"
        :auto-adjust-overflow="false" overlay-class-name="admin-product-inventory__adjustment-action"
        @getPopupContainer="getPopup">
        <template slot="title">
          <a-row style="margin-bottom: 8px;">
            <a-select id="adjustmentSelectAction" v-model="choosedAction"
              :placeholder="$t('Admins.AdminInventory.action')"
              dropdown-class-name="admin-product-inventory__adjustment-action__content-input"
              @dropdownVisibleChange="changeAction">
              <template v-for="item in inventoryAction">
                <a-select-option v-if="item.id === '1' || item.id === '2'" :key="item.id">
                  {{ item.description }}
                </a-select-option>
              </template>
            </a-select>
          </a-row>
          <a-row style="margin-bottom: 8px;">
            <a-select v-model="choosedType" :placeholder="$t('Admins.AdminInventory.type')"
              dropdown-class-name="admin-product-inventory__adjustment-action__content-input"
              @dropdownVisibleChange="changeAction">
              <a-select-option v-for="item in inventoryType" :key="item.id">
                {{ item.description }}
              </a-select-option>
            </a-select>
          </a-row>
          <a-row style="margin-bottom: 8px;">
            <a-select v-model="choosedReason" :placeholder="$t('Admins.AdminInventory.reason')"
              dropdown-class-name="admin-product-inventory__adjustment-action__content-input"
              @dropdownVisibleChange="changeAction">
              <a-select-option v-for="item in inventoryReason" :key="item.id">
                {{ item.description }}
              </a-select-option>
            </a-select>
          </a-row>
          <a-row style="margin-bottom: 8px;">
            <a-select v-model="choosedLocation" :placeholder="$t('Admins.AdminInventory.location')"
              dropdown-class-name="admin-product-inventory__adjustment-action__content-input"
              @dropdownVisibleChange="changeAction">
              <a-select-option v-for="item in inventoryLocation" :key="item.id">
                {{ item.description }}
              </a-select-option>
            </a-select>
          </a-row>
          <span v-if="choosedAction === '1'" class="admin-product-inventory__adjustment-action__action-unactive" :class="{
            'admin-product-inventory__adjustment-action__action-active':
              additionInputAction === 'lot'
          }" @click="additionInputAction = 'lot'">
            {{
              (!additionInputAction || additionInputAction !== "lot") &&
                additionLotValue &&
                additionLotValue.length > 0
                ? additionLotValue
                : additionLotDesc
            }}
          </span>
          <div v-if="additionInputAction === 'lot'">
            <textarea v-model="additionLotValue" :placeholder="$t('Admins.AdminInventory.freeform')"
              class="admin-product-inventory__adjustment-action__content-input" />
          </div>
          <span class="admin-product-inventory__adjustment-action__action-unactive" :class="{
            'admin-product-inventory__adjustment-action__action-active':
              additionInputAction === 'units'
          }" @click="additionInputAction = 'units'">
            {{
              (!additionInputAction || additionInputAction !== "units") && additionUnitsValue
                ? additionUnitsValue
                : additionUnitsDesc
            }}
          </span>
          <div v-if="additionInputAction === 'units'">
            <a-input-number v-model="additionUnitsValue" style="height: 60px;" :min="1"
              :placeholder="$t('Admins.AdminInventory.freeform')"
              class="admin-product-inventory__adjustment-action__content-input" />
          </div>
          <a-date-picker v-if="choosedAction === '1'" v-model="additionExpirationDate" :format="dateFormatString"
            dropdown-class-name="admin-product-inventory__adjustment-action__content-calendar"
            @getPopupContainer="getPopup" @openChange="changeAction">
            <span class="admin-product-inventory__adjustment-action__action-unactive">
              {{ getExpirationDate(additionExpirationDate, "Expiration") }}
            </span>
          </a-date-picker>
          <textarea v-model="inputComments" :placeholder="$t('Admins.AdminInventory.comment')"
            class="admin-product-inventory__adjustment-action__content-comments" @focus="changeAction">
  >
</textarea>
          <a-row style="margin-top: 6px;">
            <div style="width: 100%;">
              <a-upload name="file" accept="image/*,.pdf" :multiple="true" :show-upload-list="false"
                :file-list="fileList" :custom-request="doUpload" :before-upload="beforeUpload">
                <a-button class="admin-product-inventory__adjustment-action__action-btn">
                  {{ $t("Admins.AdminInventory.upload") }}
                </a-button>
              </a-upload>
            </div>
            <div class="admin-product-inventory__adjustment-action__save-btn-layer">
              <a-button class="admin-product-inventory__adjustment-action__action-btn"
                @click="clickToCreateNewInventoryRecord">
                {{ $t("Admins.AdminInventory.save") }}
              </a-button>
            </div>
          </a-row>
          <div v-if="fileList && fileList.length > 0" style="margin: 8px -8px -6px -8px; ">
            <a-row v-for="(file, index) in fileList" :key="`upload_file_${index}`"
              class="admin-product-inventory__adjustment-action__file-item" :class="choosedAction && choosedAction === '1'
                ? 'admin-product-inventory__adjustment-action__file-bg-green'
                : 'admin-product-inventory__adjustment-action__file-bg-blue'
                ">
              <a-col :span="18" class="admin-product-inventory__adjustment-action__file-name"
                :class="{ 'admin-product-inventory__modal__error-file': file.status === 'error' }">
                <a-icon v-if="file.status && file.status === 'uploading'" type="loading"
                  style="font-size: 10px; color: white;" />
                {{ file.name }}
              </a-col>
              <a-col :span="6" class="admin-product-inventory__adjustment-action__file-action"
                :class="{ 'admin-product-inventory__modal__error-file': file.status === 'error' }"
                @click="clickToRemoveFile(file)">
                {{ $t("Admins.AdminInventory.delete") }}
              </a-col>
            </a-row>
          </div>
        </template>
        <a-button v-if="isAdmin" class="admin-product-inventory__header__adjust-action"
          style="float: right; margin-right: 10px;" @click.stop="showAdjustmentDialog">
          {{ $t("Admins.AdminInventory.adjustment") }}
          <img src="~@/assets/img/inventory/adjustment_icon.svg" style="margin-left: 10px;" />
        </a-button>
      </a-popconfirm>
    </a-row>
    <a-row>
      <!-- table content -->
      <a-table id="productInventoryTable" :columns="columns" :data-source="productHistoryList"
        :row-key="record => record.privateId" :loading="isLoading" bordered :pagination="false">
        <span slot="customCheckTitle">
          <a-checkbox v-model="isCheckAllProduct" @change="handleSelectAllProductReport" />
        </span>
        <span slot="modifyDate" slot-scope="text, record">{{
          formatModifyDate(text, record)
        }}</span>
        <span slot="expirateDate" slot-scope="text, record">
          <div v-for="(date, index) in text" :key="`${record.privateId}_${date}_${index}`">
            {{ formatDate(date) }}
          </div>
        </span>
        <span slot="lotNoContent" slot-scope="text, record">
          <div v-for="(lot, index) in text" :key="`${lot}_${record.privateId}_${index}`">
            {{ lot }}
          </div>
        </span>
        <span slot="quantityContent" slot-scope="text, record">
          {{ getItemQuantity(text, record) }}
        </span>
        <span slot="availableContent" slot-scope="text">
          {{ getItemAvailable(text) }}
        </span>
        <span slot="locationContent" slot-scope="text">
          {{ text[0] }}
        </span>
        <span slot="customLocationTitle">
          {{ $t("Admins.AdminInventory.location") }}
          <a-icon type="down" :rotate="productSortLocationDesc ? 180 : 0" />
        </span>
        <span slot="checkProductAction" slot-scope="text, record">
          <a-checkbox :checked="text" @change="checkedValue => chooseProduct(checkedValue, record)" />
        </span>
        <span slot="descriptionContent" slot-scope="text, record">
          <span>{{
            record.actionPrivateId === "3"
              ? text
              : text.substring(0, 1).toUpperCase() + text.substring(1)
          }}</span>
          <a-tooltip placement="right" :title="record.comments">
            <a-icon v-if="
              text &&
              (text.toLowerCase() === 'addition' || text.toLowerCase() === 'deduction') &&
              record.comments &&
              record.comments.length > 0
            " type="message" style="color: #4A8A5D; margin-left: 9px;" />
          </a-tooltip>
        </span>
        <span slot="actionView" slot-scope="text, record" class="admin-product-inventory__content__product-view-action"
          @click="clickToView(record)">
          {{ isThisRowDataInEditStatus(record) ? "Edit" : "Details" }}
        </span>
      </a-table>
      <a-pagination class="inventoryPagination" style="margin-top: 10px; float: right;" :default-page-size="50"
        :default-current="1" :total="productHistoryReport.total" size="small" :show-quick-jumper="true"
        @change="getPageData" />
    </a-row>
    <a-modal id="orderViewDialog" v-model="isShowOrderDialog" :footer="null" :destroy-on-close="true" width="952px"
      centered :body-style="noPadding">
      <template #closeIcon>
        <img src="@/assets/img/inventory/view_by.png" />
      </template>
      <div class="admin-product-inventory__modal">
        <a-row class="admin-product-inventory__modal__black-text" style="margin-bottom: 16px;">
          {{ orderInfo ? orderInfo.landingReferenceId : "" }}
        </a-row>
        <a-row class="admin-product-inventory__modal__blue-text" style="margin-bottom: 4px;">
          <a-col :span="9">
            {{ $t("Admins.AdminInventory.name") }}
          </a-col>
          <a-col :span="3">
            {{ $t("Admins.AdminInventory.sku") }}
          </a-col>
          <a-col :span="2">
            {{ $t("Admins.AdminInventory.quantity") }}
          </a-col>
          <a-col :span="2">
            {{ $t("Admins.AdminInventory.testers") }}
          </a-col>
          <a-col :span="2">
            {{ $t("Admins.AdminInventory.msrp") }}
          </a-col>
          <a-col :span="2">
            {{ $t("Admins.AdminInventory.unitPrice") }}
          </a-col>
          <a-col :span="4">
            {{ $t("Admins.AdminInventory.amount") }}
          </a-col>
        </a-row>
        <div v-if="orderInfo && orderInfo.purchaseData" style="margin-bottom: 30px;">
          <a-row v-for="productItem in orderInfo.purchaseData" :key="`product_item_${productItem.product.id}`"
            class="admin-product-inventory__modal__black-text">
            <a-col :span="9">
              {{ productItem && productItem.product ? productItem.product.name : "" }}
            </a-col>
            <a-col :span="3">
              {{ productItem && productItem.product ? productItem.product.sku : "" }}
            </a-col>
            <a-col :span="2">
              {{
                productItem
                  ? productItem.adjusterByClientType
                    ? productItem.adjustedQuantity
                    : productItem.quantity
                  : "0"
              }}
            </a-col>
            <a-col :span="2">
              {{ productItem && productItem.testersquantity ? productItem.testersquantity : "0" }}
            </a-col>
            <a-col :span="2">
              {{ productItem ? priceFormat(productItem.recordedMsrpUsd) : priceFormat(0) }}
            </a-col>
            <a-col :span="2">
              {{
                productItem ? priceFormat(productItem.calculatedWholesalePriceUsd) : priceFormat(0)
              }}
            </a-col>
            <a-col :span="4">
              {{
                productItem ? priceFormat(productItem.calculatedWholesaleCostUsd) : priceFormat(0)
              }}
            </a-col>
          </a-row>
        </div>

        <a-row style="margin-bottom: 8px;">
          <a-col :span="20" class="admin-product-inventory__modal__red-text">
            {{ $t("Admins.AdminInventory.retailerTermsH") }}
          </a-col>
          <a-col :span="4" class="admin-product-inventory__modal__blue-text">
            {{ $t("Admins.AdminInventory.amount") }}
          </a-col>
        </a-row>
        <div v-if="orderInfo && orderInfo.charges">
          <template v-for="item in orderInfo.charges">
            <a-row v-if="item.ownedBy.id === 'retailer'" :key="`retailerTerms_${item.privateId}`"
              style="margin-bottom: 12px;">
              <a-col :span="20" class="admin-product-inventory__modal__blue-text">
                {{
                  item.chargeType.id === "custom"
                    ? "Retailer Added Additional Term"
                    : item.chargeType.name
                }}
              </a-col>
              <a-col :span="4" class="admin-product-inventory__modal__black-text">
                {{
                  item.paidBy.id === "brand"
                    ? `(${priceFormat(item.calculatedCostUsd)})`
                    : priceFormat(item.calculatedCostUsd)
                }}
              </a-col>
            </a-row>
          </template>
        </div>
        <a-row>
          <a-col :span="18" style="text-align: right;" class="admin-product-inventory__modal__blue-text">
            Total
          </a-col>
          <a-col :span="2"></a-col>
          <a-col :span="4" class="admin-product-inventory__modal__black-text">
            {{ priceFormat(retailerTotalPay) }}
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="18" class="admin-product-inventory__modal__black-text">
            {{ $t("Admins.AdminInventory.generatedOrder") }}
          </a-col>
          <a-col :span="6"> </a-col>
        </a-row>
      </div>
    </a-modal>
    <a-modal id="deductionAddViewDialog" v-model="isShowDeductionAddDialog" :footer="null" :destroy-on-close="true"
      width="952px" centered :body-style="noPadding">
      <template #closeIcon>
        <img src="@/assets/img/inventory/view_by.png" style="width: 24px; height: 24px;" />
      </template>
      <div class="admin-product-inventory__modal">
        <a-row class="admin-product-inventory__modal__black-text" style="margin-bottom: 12px;">
          {{ productHistoryDetail ? productHistoryDetail.productName : "" }}
        </a-row>
        <a-row>
          <a-col :span="18">
            <a-row class="admin-product-inventory__modal__blue-text" style="margin-bottom: 6px;">
              <a-col :span="6" style="text-align: left;">
                {{ $t("Admins.AdminInventory.modified") }}
              </a-col>
              <a-col :span="6" style="text-align: left;">
                {{ $t("Admins.AdminInventory.description") }}
              </a-col>
              <a-col :span="4">
                {{ $t("Admins.AdminInventory.type") }}
              </a-col>
              <a-col :span="4">
                {{ $t("Admins.AdminInventory.reason") }}
              </a-col>
              <a-col :span="4">
                {{ $t("Admins.AdminInventory.quantity") }}
              </a-col>
            </a-row>
            <a-row class="admin-product-inventory__modal__black-26-text">
              <a-col :span="6" style="text-align: left;">
                {{
                  productHistoryDetail
                    ? formatModifyDate(productHistoryDetail.modifyDate, productHistoryDetail)
                    : ""
                }}
              </a-col>
              <a-col :span="6" style="text-align: left;">
                <span v-if="!isShowEdit">
                  {{ productHistoryDetail ? productHistoryDetail.actionName : "" }}
                </span>
                <a-select v-else v-model="productHistoryDetail.actionPrivateId" style="width: 98%;" size="small">
                  <template v-for="item in inventoryAction">
                    <a-select-option v-if="item.id === '1' || item.id === '2'" :key="item.id">
                      {{ item.description }}
                    </a-select-option>
                  </template>
                </a-select>
              </a-col>
              <a-col :span="4">
                <span v-if="!isShowEdit">
                  {{ productHistoryDetail ? productHistoryDetail.typeName : "" }}
                </span>
                <a-select v-else v-model="productHistoryDetail.typePrivateId" style="width: 98%;" size="small">
                  <a-select-option v-for="item in inventoryType" :key="item.id">
                    {{ item.description }}
                  </a-select-option>
                </a-select>
              </a-col>
              <a-col :span="4">
                <span v-if="!isShowEdit">
                  {{ productHistoryDetail ? productHistoryDetail.reason : "" }}
                </span>
                <a-select v-else v-model="productHistoryDetail.reasonPrivateId" style="width: 98%;" size="small">
                  <a-select-option v-for="item in inventoryReason" :key="item.id">
                    {{ item.description }}
                  </a-select-option>
                </a-select>
              </a-col>
              <a-col :span="4">
                <span v-if="!isShowEdit">
                  {{
                    productHistoryDetail
                      ? productHistoryDetail.actionPrivateId === "2"
                        ? `-${productHistoryDetail.quantity}`
                        : productHistoryDetail.quantity
                      : ""
                  }}
                </span>
                <a-input v-else v-model="productHistoryDetail.quantity" placeholder="Quantity" size="small" />
              </a-col>
            </a-row>
            <a-row class="admin-product-inventory__modal__blue-text" style="margin-top: 32px; margin-bottom: 8px;">
              <a-col :span="7">
                {{
                  `Lots: ${productHistoryDetail && productHistoryDetail.inventoryHistoryChilds
                    ? productHistoryDetail.inventoryHistoryChilds.length
                    : "0"
                  }`
                }}
              </a-col>
              <a-col :span="5">
                {{ $t("Admins.AdminInventory.expiration") }}
              </a-col>
              <a-col v-if="
                productHistoryDetail &&
                productHistoryDetail.actionPrivateId === '2' &&
                isShowEdit === false
              " :span="4">
                {{ $t("Admins.AdminInventory.allocated") }}
              </a-col>
              <a-col :span="4">
                {{ $t("Admins.AdminInventory.available") }}
              </a-col>
              <a-col :span="4">
                {{ $t("Admins.AdminInventory.location") }}
              </a-col>
            </a-row>
            <div v-if="
              productHistoryDetail &&
              productHistoryDetail.inventoryHistoryChilds &&
              productHistoryDetail.inventoryHistoryChilds.length
            " style="margin-bottom: 66px;">
              <a-row v-for="lotData in productHistoryDetail.inventoryHistoryChilds"
                :key="`edit_modal_${lotData.privateId}`" style="margin-bottom: 4px;">
                <a-col :span="7">
                  <a-input v-if="
                    isShowEdit &&
                    productHistoryDetail &&
                    productHistoryDetail.actionPrivateId === '1'
                  " v-model="lotData.lotNo" placeholder="Lot No." size="small" style="width: 98%;">
                    >
                  </a-input>
                  <span v-else>{{ lotData ? lotData.lotNo : "" }}</span>
                </a-col>
                <a-col :span="5">
                  <a-date-picker v-if="
                    isShowEdit &&
                    productHistoryDetail &&
                    productHistoryDetail.actionPrivateId === '1'
                  " v-model="lotData.expirationDate" :format="dateFormatString" placeholder="Select Expiration Date"
                    size="small" style="width: 100%; height: 24px; " @getPopupContainer="getPopup">
                    <span
                      style="border: 1px solid #d9d9d9; border-radius: 4px; padding: 1.5px 4px; background-color: white; display:inline-block; width: 98%; height: 100%;">
                      {{ getExpirationDate(lotData.expirationDate, "") }}
                    </span>
                  </a-date-picker>
                  <span v-else>
                    {{ lotData ? getExpirationDate(lotData.expirationDate, "") : "" }}
                  </span>
                </a-col>
                <a-col v-if="
                  productHistoryDetail &&
                  productHistoryDetail.actionPrivateId === '2' &&
                  isShowEdit === false
                " :span="4">
                  {{
                    lotData
                      ? productHistoryDetail.actionPrivateId === "2"
                        ? `-${lotData.quantity}`
                        : lotData.quantity
                      : ""
                  }}
                </a-col>
                <a-col :span="4">
                  {{ lotData ? lotData.available : "" }}
                </a-col>
                <a-col :span="4">
                  <a-select v-if="
                    isShowEdit &&
                    productHistoryDetail &&
                    productHistoryDetail.actionPrivateId === '1'
                  " v-model="lotData.locationPrivateId" size="small" style="width: 98%;">
                    <a-select-option v-for="item in inventoryLocation" :key="item.id">
                      {{ item.description }}
                    </a-select-option>
                  </a-select>
                  <span v-else>
                    {{ lotData ? lotData.locationName : "" }}
                  </span>
                </a-col>
              </a-row>
            </div>
            <a-row style="margin-bottom: 34px;">
              <a-col :span="4" class="admin-product-inventory__modal__blue-text">
                {{ $t("Admins.AdminInventory.commentInModal") }}
              </a-col>
              <a-col :span="20" class="admin-product-inventory__modal__black-26-text">
                <span v-if="!isShowEdit">
                  {{ productHistoryDetail ? productHistoryDetail.comments : "" }}
                </span>
                <textarea v-else v-model="productHistoryDetail.comments"
                  :placeholder="$t('Admins.AdminInventory.comment')"
                  style="border: 1px solid #d9d9d9; border-radius: 4px; padding: 3px 4px; background-color: white;" />
              </a-col>
            </a-row>
            <a-row class="admin-product-inventory__modal__black-text">
              <a-col :span="18">
                {{
                  productHistoryDetail ? getActionName(productHistoryDetail.actionPrivateId) : ""
                }}
              </a-col>
              <a-col v-if="isShowEdit" :span="6">
                <button class="admin-product-inventory__modal__save-btn" @click="clickToUpdateRecord">
                  {{ $t("Admins.AdminInventory.save") }}
                </button>
              </a-col>
            </a-row>
          </a-col>
          <a-col :span="6">
            <div class="admin-product-inventory__modal__upload-file-div">
              <div v-if="
                isShowEdit &&
                productHistoryDetail &&
                productHistoryDetail.inventoryHistoryFiles.length < 5
              " style="margin-bottom: 13px;">
                <a-upload name="file" accept="image/*,.pdf" :multiple="true" :show-upload-list="false"
                  :file-list="productHistoryDetail.inventoryHistoryFiles" :custom-request="doUpload"
                  :before-upload="beforeUpload">
                  <a-button class="admin-product-inventory__modal__upload-btn">
                    {{ $t("Admins.AdminInventory.upload") }}
                  </a-button>
                </a-upload>
              </div>
              <div v-if="
                productHistoryDetail &&
                productHistoryDetail.inventoryHistoryFiles &&
                productHistoryDetail.inventoryHistoryFiles.length
              ">
                <div v-for="file in productHistoryDetail.inventoryHistoryFiles"
                  :key="`edit_modal_file_${file.privateId}`" class="admin-product-inventory__modal__upload-file-list">
                  <a v-if="isPdf(file)" :href="file.assetUrl" :class="file.status === 'error'
                    ? 'admin-product-inventory__modal__upload-file-name-error'
                    : 'admin-product-inventory__modal__upload-file-name'
                    " target="_blank">
                    <a-icon v-if="file.status && file.status === 'uploading'" type="loading"
                      style="font-size: 10px; color: white;" />
                    {{ file.assetFileName ? file.assetFileName : file.name }}
                  </a>
                  <span v-else :class="file.status === 'error'
                    ? 'admin-product-inventory__modal__upload-file-name-error'
                    : 'admin-product-inventory__modal__upload-file-name'
                    " @click="clickToViewFile(file)">
                    <a-icon v-if="file.status && file.status === 'uploading'" type="loading"
                      style="font-size: 10px; color: white;" />
                    {{ file.assetFileName ? file.assetFileName : file.name }}
                  </span>
                  <span v-if="isShowEdit" class="admin-product-inventory__modal__download-file" :class="{
                    'admin-product-inventory__modal__error-file': file.status === 'error'
                  }" @click="clickToRemoveFileFromData(file)">
                    {{ $t("Admins.AdminInventory.delete") }}
                  </span>
                  <a-icon v-else type="download" class="admin-product-inventory__modal__download-file"
                    @click="clickToDownloadFile(file)" />
                </div>
              </div>
            </div>
          </a-col>
        </a-row>
      </div>
    </a-modal>
    <a-modal v-model="isShowFileDialog" :footer="null" :destroy-on-close="true" :body-style="showFileDialog">
      <img :src="selectedFile ? selectedFile.assetUrl : ''" />
    </a-modal>
    <a-modal v-model="isUpdateSuccess" :footer="null" :closable="false" :dialog-style="{ top: '339px' }" width="393px"
      :body-style="successDialog">
      <a-row style="text-align: center">
        <span class="admin-product-inventory__modal__success-txt">{{
          $t("Admins.AdminInventory.submitUpdate")
        }}</span>
      </a-row>
      <a-row style="text-align: center; margin-top: 27px;">
        <a-button class="admin-product-inventory__modal__ok-btn" @click="hideUpdateSuccessDialog">
          {{ $t("Admins.AdminInventory.okBtn") }}
        </a-button>
      </a-row>
    </a-modal>
  </div>
</template>

<script>
import moment from "moment"
import {
  downloadCsvData,
  getNowDateStr,
  getOrderRetailerSidePayAmount,
  replaceCommaForCsvStr
} from "@/utils/validate"
import assetClient from "@/api/asset-client"
import axios from "@/api/axios"

export default {
  data() {
    return {
      productId: undefined,
      brandId: undefined,
      productName: undefined,
      brandName: undefined,
      routerName: "",
      isNeedAdjustment: true,
      totalQunatity: 0,
      allocatedQuantity: 0,
      availableQuantity: 0,
      currentPage: 1,
      countPerPage: 50,
      productSKU: undefined,
      isCheckAllProduct: false,
      productSortLocationDesc: false,
      productHistoryDetail: undefined,
      orderInfo: undefined,
      retailerTermsTotal: undefined,
      retailerTotalPay: undefined,
      isShowOrderDialog: false,
      isShowDeductionAddDialog: false,
      isShowEdit: false,
      isShowFileDialog: false,
      isUpdateSuccess: false,
      selectedFile: undefined,
      isLoadingImage: false,
      newRecord: undefined,
      choosedAction: undefined,
      choosedLocation: undefined,
      choosedType: undefined,
      choosedReason: undefined,
      additionInputAction: undefined,
      additionLotDesc: "Lot No.",
      additionUnitsDesc: "Units",
      additionLotValue: "",
      additionUnitsValue: "",
      additionExpirationDate: "",
      inputComments: "",
      fileList: [],
      uploadedFileList: [],
      productHistoryList: [],
      messageShowTime: 5,
      pagination: {
        onChange: page => {
          this.getProductHistoryData(this.getProductBaseParam(page))
        },
        size: "small",
        showQuickJumper: true,
        pageSize: 50
      },
      columns: [
        {
          key: "isCheck",
          dataIndex: "isChecked",
          slots: { title: "customCheckTitle" },
          scopedSlots: { customRender: "checkProductAction" },
          width: "42px"
        },
        {
          title: this.$t("Admins.AdminInventory.modified"),
          dataIndex: "modifyDate",
          width: "10%",
          scopedSlots: { customRender: "modifyDate" }
        },
        {
          title: this.$t("Admins.AdminInventory.description"),
          dataIndex: "description",
          scopedSlots: { customRender: "descriptionContent" },
          ellipsis: true,
          width: "20%"
        },
        {
          title: this.$t("Admins.AdminInventory.type"),
          width: "8%",
          dataIndex: "typeName"
        },
        {
          title: this.$t("Admins.AdminInventory.lotNo"),
          dataIndex: "lotNo",
          scopedSlots: { customRender: "lotNoContent" },
          width: "10%"
        },
        {
          title: this.$t("Admins.AdminInventory.expiration"),
          dataIndex: "lotExpiration",
          scopedSlots: { customRender: "expirateDate" },
          width: "11%"
        },
        {
          title: this.$t("Admins.AdminInventory.location"),
          dataIndex: "lotLocation",
          slots: { title: "customLocationTitle" },
          scopedSlots: { customRender: "locationContent" },
          width: "7%"
        },
        {
          title: this.$t("Admins.AdminInventory.quantity"),
          dataIndex: "lotQuantity",
          scopedSlots: { customRender: "quantityContent" },
          width: "10%"
        },
        {
          title: this.$t("Admins.AdminInventory.available"),
          dataIndex: "lotAvailable",
          scopedSlots: { customRender: "availableContent" },
          width: "10%"
        },
        {
          title: this.$t("Admins.AdminInventory.action"),
          dataIndex: "action",
          scopedSlots: { customRender: "actionView" }
        }
      ],
      selectedProductsInProductView: new Set(),
      noPadding: {
        padding: "20px",
        background: "#FAFAFA"
      },
      showFileDialog: {
        padding: "0px",
        background: "#FAFAFA"
        // 'min-height': '200px',
        // 'text-align': 'center'
      },
      successDialog: {
        padding: "50px 0px",
        background: "#F6F9FD"
      },
      dateFormatString: "MM/DD/YYYY",
      adjustmentVisible: false,
      isLoading: false
    }
  },
  computed: {
    productHistoryListAll() {
      return this.$store.getters["inventory/productHistoryList"]
    },
    productHistoryReport() {
      return this.$store.getters["inventory/productHistory"]
    },
    product() {
      return this.$store.getters["product/product"]
    },
    inventoryAction() {
      return this.$store.getters["reference/inventoryAction"]
    },
    inventoryType() {
      return this.$store.getters["reference/inventoryType"]
    },
    inventoryReason() {
      return this.$store.getters["reference/inventoryReason"]
    },
    inventoryLocation() {
      return this.$store.getters["reference/inventoryLocation"]
    },
    productInfo() {
      return this.$store.getters["inventory/productInfo"]
    },
    isAdmin() {
      return this.$store.getters["user/isAdmin"]
    },
    isBrandUseLandingLogistic() {
      return this.$store.getters["brand/isBrandUseLandingLogistic"]
    }
  },
  watch: {
    productHistoryReport(newValue) {
      if (newValue) {
        this.pagination.total = newValue.total
        this.pagination.current = newValue.pageIndex
      }
    },
    productHistoryListAll() {
      if (this.isAdmin) {
        this.productHistoryList = this.productHistoryListAll
      } else {
        this.productHistoryList = this.productHistoryListAll.filter(item => {
          if (item.actionPrivateId !== "4") {
            return true
          }
          return false
        })
      }
    },
    product(newValue) {
      if (newValue) {
        if (!this.brandId) {
          this.brandId = newValue.brand.id
        }
        if (!this.productSKU) {
          this.productSKU = newValue.sku
        }
        if (!this.productName || this.productName.length > 0) {
          this.productName = newValue.name
        }
      }
    },
    productInfo(newValue) {
      if (
        newValue &&
        newValue.productInventoryReport &&
        newValue.productInventoryReport.length > 0
      ) {
        this.totalQunatity = 0
        this.availableQuantity = 0
        this.allocatedQuantity = 0
        this.brandName = newValue.productInventoryReport[0].brandName
        newValue.productInventoryReport.forEach(item => {
          this.totalQunatity += item.total
          this.availableQuantity += item.available
          this.allocatedQuantity += item.allocated
        })
      }
    }
  },
  async created() {
    this.routerName = this.$route.name
    this.productId = this.$route.params.productId
    this.brandId = this.$route.params.brandId
    await this.$store.dispatch("product/getProduct", this.productId)
    this.getProductTotalAvailableData()
    this.getProductHistoryData(this.getProductBaseParam(1))
    this.isNeedAdjustment =
      this.routerName === "brandProductInventory" ? !this.isBrandUseLandingLogistic : true
  },
  methods: {
    clickBack() {
      this.$router.go(-1)
    },
    getPageData(page) {
      this.getProductHistoryData(this.getProductBaseParam(page))
    },
    async getProductTotalAvailableData() {
      await this.$store.dispatch("inventory/fetchProductData", {
        "brand-Id": this.brandId,
        "product-Id": this.productId
      })
    },
    getActionName(id) {
      let data = this.$store.getters["reference/findInventoryAction"](id)
      let name = data && data.name
      if (name) {
        name = name.charAt(0).toUpperCase() + name.slice(1)
      }
      return name
    },
    getExpirationDate(date, desc) {
      if (date) {
        return moment(date).format(this.dateFormatString)
      } else {
        return desc
      }
    },
    getEditExpirationDate(date) {
      return moment(date).format("YYYY-MM-DD")
    },
    changeAction() {
      this.additionInputAction = undefined
      // if (this.additionUnitsValue && this.additionUnitsValue.length > 0) {
      //   this.additionUnitsDesc = this.additionUnitsValue
      // }
      // if (this.additionLotValue && this.additionLotValue.length > 0) {
      //   this.additionLotDesc = this.additionLotValue
      // }
    },
    priceFormat(price) {
      const numberFormat = new Intl.NumberFormat("en-US", { style: "currency", currency: "USD" })
      return numberFormat.format(price)
    },
    countryName(id) {
      return this.$store.getters["reference/countryName"](id)
    },
    getBaseParam(page) {
      let params = {
        _limit: this.pagination.pageSize,
        _index: page,
        productId: this.productId
      }
      return params
    },
    getProductBaseParam(page) {
      let params = {
        _limit: this.pagination.pageSize,
        _index: page,
        "_order-by": "PREVIOUS",
        "product-Id": this.productId
      }
      return params
    },
    getProductHistoryData(params) {
      this.isLoading = true
      this.$store
        .dispatch("inventory/fetchProductHistory", params)
        .then(() => {
          this.isLoading = false
        })
        .catch(() => {
          this.isLoading = false
        })
    },
    handleSelectAllProductReport() {
      this.selectedProductsInProductView.clear()
      this.productHistoryList.forEach(item => {
        item.isChecked = this.isCheckAllProduct
        this.selectedProductsInProductView.add(item)
      })
    },
    formatModifyDate(value, record) {
      console.log("......formatModifyDate.....")
      console.log(value)
      console.log(record)
      console.log(record.updatedAt)
      if (value) {
        let timesStr = value.toString().split("-")
        if (timesStr.length === 3) {
          return `${timesStr[1]}/${timesStr[2]}/${timesStr[0]}`
        }
      } else if (record.updatedAt) {
        return this.formatDate(record.updatedAt)
      }
      return " "
    },
    formatDate(value) {
      if (value) {
        if (value.length > 10) {
          return this.formateDateTime(value)
        } else {
          let time = value.replace(/-/g, "/")
          return this.formateDateTime(time)
        }
      }
      return " "
    },
    formateDateTime(time) {
      let date = Date.parse(time)
      let str = new Intl.DateTimeFormat("en-US").format(date)
      return str
    },
    chooseProduct(checkedValue, record) {
      record.isChecked = checkedValue.target.checked
      if (checkedValue.target.checked) {
        // add record into the list
        this.selectedProductsInProductView.add(record)
      } else {
        // remove it form list
        this.selectedProductsInProductView.delete(record)
      }
    },
    clickToView(item) {
      console.log(item)
      this.productHistoryDetail = Object.assign({}, item)
      this.productHistoryDetail.inventoryHistoryFiles = []
      this.productHistoryDetail.inventoryHistoryChilds = []
      if (item.inventoryHistoryFiles && item.inventoryHistoryFiles.length > 0) {
        for (let i = 0; i < item.inventoryHistoryFiles.length; i++) {
          this.productHistoryDetail.inventoryHistoryFiles.push(
            Object.assign({}, item.inventoryHistoryFiles[i])
          )
        }
      }
      if (item.inventoryHistoryChilds && item.inventoryHistoryChilds.length > 0) {
        for (let i = 0; i < item.inventoryHistoryChilds.length; i++) {
          this.productHistoryDetail.inventoryHistoryChilds.push(
            Object.assign({}, item.inventoryHistoryChilds[i])
          )
        }
      }
      if (item.actionPrivateId === "3" || item.actionPrivateId === "4") {
        console.log(`orderId: ${item.orderId} , ${item.actionId}`)
        let orderId = item.orderId
        this.getOrderDetails(orderId)
      } else {
        this.isShowEdit = this.isThisRowDataInEditStatus(item)
        if (this.isShowEdit && !item.inventoryHistoryFiles) {
          item.inventoryHistoryFiles = []
        }
        this.isShowDeductionAddDialog = true
      }
    },
    getOrderDetails(orderId) {
      this.$store
        .dispatch("order/getOrder", orderId)
        .then(() => {
          this.orderInfo = this.$store.getters["order/order"]
          this.retailerTotalPay = getOrderRetailerSidePayAmount(this.orderInfo)
          this.retailerTermsTotal =
            this.retailerTotalPay - parseFloat(this.orderInfo.calculatedWholesaleCostUsd)
          this.isShowOrderDialog = true
          this.orderInfo.purchaseData = this.orderDetails(this.orderInfo)
          console.log("....get order details.....")
          console.log(this.orderInfo)
        })
        .finally(() => { })
    },
    orderDetails(order) {
      let purchase = order && order.items.filter(item => item.quantityType.id == "purchase")
      let testers = order && order.items.filter(item => item.quantityType.id == "testers")
      if (purchase.length && testers.length) {
        purchase.forEach(purchaseElement => {
          testers.forEach(testerElement => {
            if (purchaseElement.product.id == testerElement.product.id) {
              purchaseElement.testersquantity = testerElement.adjusterByClientType
                ? testerElement.adjustedQuantity
                : testerElement.quantity
            }
          })
        })
      }
      return purchase
    },
    getPopup(triggerNode) {
      return triggerNode
    },
    isThisRowDataInEditStatus(item) {
      if (item.actionPrivateId === "3" || item.actionPrivateId === "4") {
        return false
      }
      // not allow to edit if addition and remaining is not same as quantity
      if (item.actionPrivateId == 1
        && item.inventoryHistoryChilds.length == 1
        && item.inventoryHistoryChilds[0].remaining != item.inventoryHistoryChilds[0].quantity) {
        return false
      }
      let createDate = new Date(item.createdAt)
      let end = this.getMidnight(
        createDate.getFullYear(),
        createDate.getMonth() + 1,
        createDate.getDate()
      )
      let now = new Date().getTime()
      let diff = end - now
      if (diff > 0) {
        return true
      }
      return false
    },
    getMidnight(year, month, day) {
      let date = new Date(`${year}-${month}-${day} 23:59:59`)
      return date.getTime()
    },
    clickToUpdateRecord() {
      if (this.productHistoryDetail) {
        let historyChild = []
        if (
          this.productHistoryDetail.inventoryHistoryChilds &&
          this.productHistoryDetail.inventoryHistoryChilds.length
        ) {
          for (let i = 0; i < this.productHistoryDetail.inventoryHistoryChilds.length; i++) {
            if (
              this.productHistoryDetail.actionPrivateId === "1" &&
              !this.productHistoryDetail.inventoryHistoryChilds[i].expirationDate
            ) {
              this.$message.warn("Please choose the expiration date!", this.messageShowTime)
              return
            }
            historyChild.push({
              privateId: this.productHistoryDetail.inventoryHistoryChilds[i].privateId,
              inventoryHistoryPrivateId: this.productHistoryDetail.inventoryHistoryChilds[i]
                .inventoryHistoryPrivateId,
              productId: this.productHistoryDetail.inventoryHistoryChilds[i].productId,
              brandId: this.productHistoryDetail.inventoryHistoryChilds[i].brandId,
              locationPrivateId: this.productHistoryDetail.inventoryHistoryChilds[i]
                .locationPrivateId,
              expirationDate: this.productHistoryDetail.inventoryHistoryChilds[i].expirationDate,
              lotNo: this.productHistoryDetail.inventoryHistoryChilds[i].lotNo
            })
          }
        }
        let historyFiles = []
        if (
          this.productHistoryDetail.inventoryHistoryFiles &&
          this.productHistoryDetail.inventoryHistoryFiles.length
        ) {
          for (let i = 0; i < this.productHistoryDetail.inventoryHistoryFiles.length; i++) {
            if (this.productHistoryDetail.inventoryHistoryFiles[i].assetId) {
              historyFiles.push({
                assetId: this.productHistoryDetail.inventoryHistoryFiles[i].assetId,
                assetContentType: this.productHistoryDetail.inventoryHistoryFiles[i]
                  .assetContentType,
                assetUrl: this.productHistoryDetail.inventoryHistoryFiles[i].assetUrl,
                assetFileName: this.productHistoryDetail.inventoryHistoryFiles[i].assetFileName
              })
            }
          }
        }
        let reasonObj = this.$store.getters["reference/findInventoryReason"](
          this.productHistoryDetail.reasonPrivateId
        )
        let param = {
          privateId: this.productHistoryDetail.privateId,
          typePrivateId: this.productHistoryDetail.typePrivateId,
          productId: this.productHistoryDetail.productId,
          brandId: this.productHistoryDetail.brandId,
          quantity: this.productHistoryDetail.quantity,
          units: this.productHistoryDetail.units,
          actionPrivateId: this.productHistoryDetail.actionPrivateId,
          createdAt: this.productHistoryDetail.createdAt,
          reason: reasonObj ? reasonObj.name : this.productHistoryDetail.reason,
          comments: this.productHistoryDetail.comments,
          modifyDate: this.productHistoryDetail.modifyDate,
          inventoryHistoryChilds: historyChild,
          inventoryHistoryFiles: historyFiles
        }
        this.$store
          .dispatch("inventory/updateProductInventory", param)
          .then(() => {
            this.getProductHistoryData(this.getProductBaseParam(1))
            this.isShowDeductionAddDialog = false
            this.getProductTotalAvailableData()
            this.showUpdateSuccessDialog()
          })
          .catch(error => {
            console.error(error)
            this.$message.error(error.message, this.messageShowTime)
          })
      }
    },
    showUpdateSuccessDialog() {
      this.isUpdateSuccess = true
    },
    hideUpdateSuccessDialog() {
      this.isUpdateSuccess = false
    },
    // download csv
    async clickToDownloadData() {
      if (this.selectedProductsInProductView && this.selectedProductsInProductView.size > 0) {
        await downloadCsvData(
          this.generateDownloadStrForProduct(),
          `${this.brandName ? this.brandName : "brand"}_${this.productSKU}_${getNowDateStr()}.csv`
        )
      } else {
        this.$message.warning(
          this.$t("Admins.AdminInventory.downloadTooltip"),
          this.messageShowTime
        )
      }
    },
    generateDownloadStrForProduct() {
      if (this.selectedProductsInProductView && this.selectedProductsInProductView.size > 0) {
        let csvData = [
          "Modified, Description, Type, Lot No., Expiration, Location, Quantity, Available, Reason, Comments"
        ]
        for (let item of this.selectedProductsInProductView) {
          if (item.inventoryHistorys && item.inventoryHistorys.length > 0) {
            for (let i = 0; i < item.inventoryHistorys.length; i++) {
              csvData.push(this.generateOneRowData(item.inventoryHistorys[i]))
            }
          } else {
            csvData.push(this.generateOneRowData(item))
          }
        }
        return csvData
      }
      return []
    },
    generateOneRowData(item) {
      console.log(item)
      let str = `${this.formatModifyDate(item.modifyDate, item)}, ${replaceCommaForCsvStr(
        item.description
      )}, ${item.typeName}, ${item.lotNo}, ${this.formatDate(item.lotExpiration)}, ${item.lotLocation
        }, ${item.lotQuantity}, ${item.lotAvailable}, ${item.reason}, ${item.comments ? replaceCommaForCsvStr(item.comments) : ""
        } `
      return str
    },
    // download record uploaded file.
    async clickToDownloadFile(file) {
      await this.$store.dispatch("asset/getAsset", file.assetId)
      const asset = this.$store.getters["asset/find"](file.assetId)
      axios({
        url: asset.url,
        method: "GET",
        responseType: "blob"
      }).then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement("a")
        link.href = url
        link.setAttribute("download", asset.originalFileName)
        document.body.appendChild(link)
        link.click()
        window.URL.revokeObjectURL(url)
        link.remove()
        this.wait = false
      })
    },
    showAdjustmentDialog() {
      this.adjustmentVisible = true
    },
    clickToViewFile(data) {
      if (!data.status || data.status === "done") {
        this.selectedFile = data
        this.isShowFileDialog = true
        this.isLoadingImage = true
      }
    },
    loadImage() {
      this.isLoadingImage = false
    },
    clickToRemoveFileFromData(file) {
      if (
        this.productHistoryDetail &&
        this.productHistoryDetail.inventoryHistoryFiles &&
        this.productHistoryDetail.inventoryHistoryFiles.length
      ) {
        const index = this.productHistoryDetail.inventoryHistoryFiles.indexOf(file)
        const newFileList = this.productHistoryDetail.inventoryHistoryFiles.slice()
        newFileList.splice(index, 1)
        this.productHistoryDetail.inventoryHistoryFiles = newFileList
      }
    },
    clickToRemoveFile(file) {
      const index = this.fileList.indexOf(file)
      const newFileList = this.fileList.slice()
      newFileList.splice(index, 1)
      this.fileList = newFileList
    },
    beforeUpload(file) {
      file.status = "uploading"
      if (this.isShowDeductionAddDialog && this.isShowEdit) {
        if (
          this.productHistoryDetail.inventoryHistoryFiles &&
          this.productHistoryDetail.inventoryHistoryFiles.length >= 5
        ) {
          return false
        }
        this.productHistoryDetail.inventoryHistoryFiles.push(file)
        return true
      } else {
        if (this.fileList && this.fileList.length >= 5) {
          return false
        }
        this.fileList.push(file)
        return true
      }
    },
    initAdjustment() {
      this.choosedType = undefined
      this.additionUnitsValue = undefined
      this.choosedAction = undefined
      this.choosedReason = undefined
      this.inputComments = undefined
      this.choosedLocation = undefined
      this.additionLotValue = undefined
      this.additionExpirationDate = undefined
      this.fileList = []
      this.uploadedFileList = []
    },
    clickToHidePopup() {
      this.adjustmentVisible = false
    },
    clickToCreateNewInventoryRecord() {
      if (!this.choosedType) {
        this.$message.warn("Please choose the type!", this.messageShowTime)
        return
      }
      if (!this.choosedReason) {
        this.$message.warn("Please choose the reason!", this.messageShowTime)
        return
      }
      if (!this.choosedLocation) {
        this.$message.warn("Please choose the location!", this.messageShowTime)
        return
      }
      if (this.choosedAction === "1" && !this.additionLotValue) {
        this.$message.warn("Please input the Lot number!", this.messageShowTime)
        return
      }
      if (!this.additionUnitsValue) {
        this.$message.warn("Please input the units!", this.messageShowTime)
        return
      }
      if (this.choosedAction === "1" && !this.additionExpirationDate) {
        this.$message.warn("Please choose the expiration date!", this.messageShowTime)
        return
      }
      let imgs = []
      if (this.uploadedFileList && this.uploadedFileList.length > 0) {
        for (let i = 0; i < this.uploadedFileList.length; i++) {
          imgs.push({
            assetId: this.uploadedFileList[i].id,
            assetFileName: this.uploadedFileList[i].originalFileName,
            assetContentType: this.uploadedFileList[i].contentType,
            assetUrl: this.uploadedFileList[i].url
          })
        }
      }
      let reasonObj = this.$store.getters["reference/findInventoryReason"](this.choosedReason)
      let param = {
        typePrivateId: this.choosedType,
        productId: this.productId,
        brandId: this.brandId ? this.brandId : this.product.brandId,
        quantity: this.additionUnitsValue,
        actionPrivateId: this.choosedAction,
        reason: reasonObj ? reasonObj.name : this.choosedReason,
        comments: this.inputComments,
        units: this.additionUnitsValue,
        inventoryHistoryFiles: imgs
      }
      if (this.choosedAction === "1") {
        param.inventoryHistoryChilds = [
          {
            locationPrivateId: this.choosedLocation,
            expirationDate: this.getEditExpirationDate(this.additionExpirationDate),
            lotNo: this.additionLotValue
          }
        ]
      } else {
        param.inventoryHistoryChilds = [
          {
            locationPrivateId: this.choosedLocation
          }
        ]
      }
      this.$store
        .dispatch("inventory/createProductInventory", param)
        .then(() => {
          this.$message.success("create success!", this.messageShowTime)
          this.getProductHistoryData(this.getProductBaseParam(1))
          this.adjustmentVisible = false
          this.initAdjustment()
          this.getProductTotalAvailableData()
        })
        .catch(error => {
          console.error(error)
          this.$message.error(error.message)
        })
    },
    // upload files
    doUpload(data) {
      assetClient
        .createAsset(data.file, this.brandId)
        .then(response => {
          if (response && response.data && response.data.length) {
            data.file.status = "done"
            if (this.isShowDeductionAddDialog && this.isShowEdit) {
              let uploadedFile = response.data[0].file
              this.productHistoryDetail.inventoryHistoryFiles.forEach(item => {
                if (item.uid === data.file.uid) {
                  item.assignedFileName = uploadedFile.assignedFileName
                  item.characterSet = uploadedFile.characterSet
                  item.assetId = uploadedFile.id
                  item.assetContentType = uploadedFile.contentType
                  item.assetUrl = uploadedFile.url
                  item.assetFileName = uploadedFile.originalFileName
                  item.status = "done"
                }
              })
            } else {
              this.uploadedFileList.push(response.data[0].file)
            }
            this.$forceUpdate()
          }
        })
        .catch(error => {
          console.error(error)
          data.file.status = "error"
          this.$forceUpdate()
        })
    },
    isPdf(file) {
      let name = file.assetFileName ? file.assetFileName : file.name
      if (name.indexOf(".pdf") > 0) {
        return true
      }
      return false
    },
    getItemQuantity(data, record) {
      let quan = 0
      if (
        record.actionPrivateId &&
        record.actionPrivateId !== "1" &&
        record.actionPrivateId !== "4"
      ) {
        for (let i = 0; i < data.length; i++) {
          quan -= data[i]
        }
      } else {
        for (let i = 0; i < data.length; i++) {
          quan += data[i]
        }
      }
      return quan
    },
    getItemAvailable(record) {
      let data = record[0]
      for (let i = 1; i < record.length; i++) {
        if (record[i] < data) {
          data = record[i]
        }
      }
      return data
    }
  }
}
</script>

<style lang="scss">
.admin-product-inventory {
  padding: 0px 10px;

  &__header {
    margin-left: auto !important;
    margin-right: auto !important;
    max-width: 935px;

    &__title {
      font-family: Playfair Display;
      font-style: normal;
      font-weight: bold;
      font-size: 48px;
      line-height: 64px;
      color: #000000;
    }

    &__back-btn {
      background: #dee5ef !important;
      border-radius: 2px;
      border: none;
      width: 88px;
      height: 30px;
      font-family: Proxima Nova;
      font-style: normal;
      font-weight: normal;
      font-size: 14px !important;
      line-height: 22px;
      color: #000000 !important;
    }

    &__download-btn {
      background: #fafafa;
      border: 1px solid #1d4ba1 !important;
      box-sizing: border-box;
      border-radius: 2px;
      font-family: Proxima Nova;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 24px;
      color: #1d4ba1 !important;
      margin-left: 10px;
    }

    &__adjust-action {
      background: #fafafa;
      border: 1px solid #1d4ba1 !important;
      box-sizing: border-box;
      border-radius: 2px;
      font-family: Proxima Nova;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 24px;
      color: #1d4ba1 !important;
    }

    &__product-info {
      font-family: Proxima Nova;
      font-style: normal;
      font-weight: normal;
      color: #262626;
    }

    &__product-info-name {
      font-size: 20px;
      line-height: 24px;
      max-width: 423px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      margin-bottom: 20px;
    }

    &__product-info-other {
      font-size: 14px;
      line-height: 24px;
      margin-bottom: 8px;
    }

    &__product-info-other-left {
      margin-left: 10px;
      padding: 0px 8px;
    }

    &__product-info-number {
      padding-top: 8px;
      margin-bottom: 4px;
      font-size: 24px;
      line-height: 24px;
      text-align: center;
    }

    &__product-info-number-desc {
      font-size: 14px;
      line-height: 24px;
      color: #73839c;
      text-align: center;
      padding-bottom: 8px;
    }

    &__product-info-bg {
      background: #f6f9fd;
      border-radius: 2px;
    }
  }

  &__content {
    &__product-view-action {
      font-family: Proxima Nova;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 22px;
      color: #4a8a5d;
      cursor: pointer;
    }
  }

  &__adjustment-action {
    width: 172px;
    background: #a4b0c0;
    border-radius: 2px;
    padding: 8px;

    &__btn {
      width: 156px;
      height: 22px;
      background: #f6f9fd;
      border-radius: 2px;
      font-family: Proxima Nova;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 22px;
      color: #262626;
    }

    &__btn-active {
      background: #4a8a5d;
      border-radius: 2px;
      color: #ffffff;
    }

    &__content-input {
      width: 156px !important;
      background: #394a64 !important;
      border-radius: 2px !important;
      font-family: Proxima Nova;
      font-style: normal;
      font-weight: normal;
      font-size: 14px !important;
      line-height: 22px !important;
      padding: 5px 6px !important;
      color: white;
    }

    &__content-calendar {
      width: 172px;
      background: #394a64 !important;
      border-radius: 2px !important;
      font-family: Proxima Nova !important;
      font-style: normal;
      font-weight: normal;
      font-size: 6px !important;
      padding: 2px !important;
    }

    &__content-comments {
      width: 156px;
      background: #f6f9fd;
      border-radius: 2px;
      font-family: Proxima Nova;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 22px;
      color: #262626;
      padding-left: 12px;
    }

    &__action-unactive {
      width: 156px;
      height: 22px;
      background: #f6f9fd;
      border-radius: 2px;
      font-family: Proxima Nova;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 22px;
      color: #262626;
      cursor: pointer;
      display: block;
      padding-left: 12px;
      margin-bottom: 8px;
    }

    &__action-active {
      border: none;
      background: #4a8a5d !important;
      border-radius: 2px !important;
      color: white !important;
    }

    &__save-btn-layer {
      width: 49px;
      height: 22px;
      float: right;
      position: absolute;
      top: 0px;
      right: 0px;
      z-index: 3;
    }

    &__action-btn {
      width: 49px;
      height: 22px !important;
      background: #f6f9fd !important;
      border-radius: 2px !important;
      font-family: Proxima Nova;
      font-style: normal;
      font-weight: normal !important;
      font-size: 14px !important;
      line-height: 22px !important;
      color: #262626 !important;
      padding: 0px !important;
    }

    &__file-bg-green {
      background: #4a8a5d;
    }

    &__file-bg-blue {
      background: #1d4ba1;
    }

    &__file-item {
      border-radius: 2px;
      font-family: Proxima Nova;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 17px;
      color: #ffffff;
      padding: 1px 8px;
    }

    &__file-name {
      overflow: hidden;
      width: 100%;
      white-space: nowrap;
      display: inline-block;
      text-overflow: ellipsis;
    }

    &__file-action {
      cursor: pointer;
    }
  }

  &__modal {
    font-family: Proxima Nova;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;

    &__black-text {
      color: #394a64;
    }

    &__red-text {
      color: #ff6132;
    }

    &__blue-text {
      color: #1d4ba1;
    }

    &__black-26-text {
      color: #262626;
    }

    &__upload-file-div {
      max-height: 150px;
      width: 172px;
      margin-top: 180px;
      float: right;
    }

    &__upload-file-list {
      width: 100%;
      background: #1d4ba1;
      border-radius: 2px;
      font-family: Proxima Nova;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 17px;
      color: #ffffff;
      padding: 1px 4px 1px 8px;
      margin-bottom: 1px;
      height: 19px;
      float: bottom;
    }

    &__upload-file-name {
      float: left;
      width: 100px;
      text-overflow: ellipsis;
      overflow: hidden;
      display: inline-block;
      white-space: nowrap;
      color: white;
    }

    &__upload-file-name-error {
      float: left;
      width: 100px;
      text-overflow: ellipsis;
      overflow: hidden;
      display: inline-block;
      white-space: nowrap;
      color: red;
    }

    &__download-file {
      float: right;
      cursor: pointer;
      color: white;
    }

    &__error-file {
      color: red;
    }

    &__save-btn {
      width: 88px;
      height: 30px;
      background: #dee5ef;
      border-radius: 2px;
      font-family: Proxima Nova;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 22px;
      color: #000000;
    }

    &__upload-btn {
      width: 49px;
      height: 22px !important;
      background: #dee5ef !important;
      border-radius: 2px !important;
      border: none !important;
      font-family: Proxima Nova;
      font-style: normal;
      font-weight: normal;
      font-size: 14px !important;
      line-height: 22px;
      color: #262626 !important;
      padding: 1px !important;
    }

    &__success-txt {
      font-family: Proxima Nova;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 22px;
      color: #000000;
      margin-top: 48px;
    }

    &__ok-btn {
      background: #394a64 !important;
      border-radius: 2px !important;
      width: 130px;
      height: 30px !important;
      font-family: Proxima Nova;
      font-style: normal;
      font-weight: normal;
      font-size: 16px !important;
      line-height: 30px !important;
      color: white !important;
      border: none !important;
    }
  }
}

.admin-product-inventory__adjust-action>.ant-popover-content>.ant-popover-inner>.ant-popover-inner-content .ant-row>.ant-select>.ant-select-selection—-single {
  width: 156px;
  height: 22px;
  background: #f6f9fd;
  border-radius: 2px;
  font-family: Proxima Nova;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: #262626;
}

.admin-product-inventory__adjustment-action.ant-popover {
  width: 172px;
  background: #a4b0c0;
  border-radius: 2px;
  padding: 8px 8px 6px;
}

.admin-product-inventory__adjustment-action>.ant-popover-content>.ant-popover-arrow {
  display: none;
}

.admin-product-inventory__adjustment-action>.ant-popover-content>.ant-popover-inner {
  background-color: transparent;
  border: none;
  box-shadow: none;
}

.admin-product-inventory__adjustment-action>.ant-popover-content>.ant-popover-inner>.ant-popover-inner-content>.ant-popover-message {
  padding: 0px;
}

.admin-product-inventory__adjustment-action>.ant-popover-content>.ant-popover-inner>.ant-popover-inner-content>.ant-popover-message>.anticon {
  display: none;
}

.admin-product-inventory__adjustment-action>.ant-popover-content>.ant-popover-inner>.ant-popover-inner-content {
  padding: 0px;
}

.admin-product-inventory__adjustment-action>.ant-popover-content>.ant-popover-inner>.ant-popover-inner-content>.ant-popover-message>.ant-popover-message-title {
  padding-left: 0px;
}

.admin-product-inventory__adjustment-action .ant-popover-buttons {
  display: none;
}

.admin-product-inventory__adjustment-action .ant-select-selection__rendered {
  line-height: 22px !important;
}

.admin-product-inventory__adjustment-action .ant-select-open .ant-select-selection {
  border: none;
  background: #4a8a5d !important;
  border-radius: 2px !important;
  color: white;
}

.admin-product-inventory__adjustment-action .ant-select-open .ant-select-selection__placeholder {
  color: white;
}

.admin-product-inventory__adjustment-action .ant-select-selection--single {
  width: 156px;
  background: #f6f9fd;
  border-radius: 2px;
  height: 22px;
}

.admin-product-inventory__adjustment-action .ant-select-arrow .ant-select-arrow-icon {
  display: none;
}

.admin-product-inventory__adjustment-action .ant-select-selection-selected-value {
  font-family: Proxima Nova;
  font-style: normal;
  font-weight: normal;
  font-size: 14px !important;
}

.admin-product-inventory__adjustment-action .ant-select-selection__placeholder {
  font-family: Proxima Nova;
  font-style: normal;
  font-weight: normal;
  font-size: 14px !important;
  color: #262626;
}

.admin-product-inventory__adjustment-action__content-input .ant-select-dropdown-menu-item {
  color: white !important;
  background: #192b46 !important;
  border-radius: 2px;
  padding-left: 6px;
  margin-bottom: 5px;
}

.admin-product-inventory__adjustment-action__content-input .ant-select-dropdown-menu-item-active {
  background-color: #192b46 !important;
}

#orderViewDialog .ant-modal-close-x {
  width: 40px;
  height: 40px;
  line-height: 40px;

  img {
    margin-left: 0px;
  }
}

#deductionAddViewDialog .ant-modal-close-x {
  width: 40px;
  height: 40px;
  line-height: 40px;

  img {
    margin-left: 0px;
  }
}

#productInventoryTable .ant-pagination-disabled .ant-pagination-item-link {
  color: #5e6871;
}

#productInventoryTable .ant-pagination-item-active {
  background: #8c8c8c;
  border-radius: 2px;
  border: none;
}

#productInventoryTable .ant-pagination-item.ant-pagination-item-active a {
  color: white;
}

#productInventoryTable .ant-pagination-item a {
  font-family: Proxima Nova;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #262626;
}

.admin-product-inventory__modal__upload-file-name:hover {
  text-decoration: underline;
}

.admin-product-inventory__adjustment-action__content-input::-webkit-input-placeholder {
  color: white;
  font-size: 14px;
  line-height: 22px;
}

.admin-product-inventory__adjustment-action__content-comments::-webkit-input-placeholder {
  color: #262626;
}

.admin-product-inventory__adjustment-action__content-comments:focus {
  background: #4a8a5d;
  border-radius: 2px;
  color: #ffffff;
}

.admin-product-inventory__adjustment-action__content-comments:focus::-webkit-input-placeholder {
  color: #ffffff;
}

.admin-product-inventory__adjustment-action__content-calendar .ant-calendar {
  width: 168px;
  font-size: 6px;
  border-radius: 2px;
  line-height: 1;
  box-shadow: none;
  background-color: transparent;
  border: none;
}

.admin-product-inventory__adjustment-action__content-calendar .ant-calendar-input-wrap {
  display: none;
}

.admin-product-inventory__adjustment-action__content-calendar .ant-calendar-header {
  height: 20px;
  line-height: 20px;
  border-bottom: none;
  background: #73839c;
  border-radius: 1px;
  margin-bottom: 2px;
}

.admin-product-inventory__adjustment-action__content-calendar .ant-calendar-header a {
  color: white;
}

.admin-product-inventory__adjustment-action__content-calendar .ant-calendar-header .ant-calendar-prev-year-btn,
.admin-product-inventory__adjustment-action__content-calendar .ant-calendar-header .ant-calendar-prev-month-btn,
.admin-product-inventory__adjustment-action__content-calendar .ant-calendar-header .ant-calendar-month-select,
.admin-product-inventory__adjustment-action__content-calendar .ant-calendar-header .ant-calendar-year-select,
.admin-product-inventory__adjustment-action__content-calendar .ant-calendar-header .ant-calendar-next-month-btn,
.admin-product-inventory__adjustment-action__content-calendar .ant-calendar-header .ant-calendar-next-year-btn {
  color: white;
  line-height: 20px;
  font-size: 10px;
  height: 20px;
}

.admin-product-inventory__adjustment-action__content-calendar .ant-calendar-body {
  padding: 0px;
}

.admin-product-inventory__adjustment-action__content-calendar .ant-calendar-body thead .ant-calendar-column-header .ant-calendar-column-header-inner {
  color: white;
  background: #73839c;
  border-radius: 1px;
  font-size: 4px;
  margin-right: 2px;
  text-align: center;
}

.admin-product-inventory__adjustment-action__content-calendar .ant-calendar-body .ant-calendar-column-header {
  padding: 2px 0px;
}

.admin-product-inventory__adjustment-action__content-calendar .ant-calendar-body .ant-calendar-cell {
  padding: 0px;
  height: 24px;
}

.admin-product-inventory__adjustment-action__content-calendar .ant-calendar-body .ant-calendar-cell>.ant-calendar-date {
  width: 22px;
  margin: 1px;
  padding: 0px;
  color: white;
  background: #73839c;
  border-radius: 1px;
}

.admin-product-inventory__adjustment-action__content-calendar .ant-calendar-body .ant-calendar-cell.ant-calendar-last-month-cell>.ant-calendar-date,
.admin-product-inventory__adjustment-action__content-calendar .ant-calendar-body .ant-calendar-cell.ant-calendar-next-month-btn-day>.ant-calendar-date {
  color: #d5d5d5;
}

.admin-product-inventory__adjustment-action__content-calendar .ant-calendar-footer {
  display: none;
}

.admin-product-inventory__adjustment-action .ant-upload-list-item {
  background: #4a8a5d;
  border-radius: 2px;
  font-family: Proxima Nova;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  margin-top: 1px;
}

.admin-product-inventory__adjustment-action .ant-upload-list {
  padding-top: 7px;
}

.admin-product-inventory__adjustment-action .ant-upload-list-item-info {
  font-size: 14px;
  font-family: Proxima Nova;
  font-style: normal;
  font-weight: normal;
  padding: 0px 2px;
}

.admin-product-inventory__adjustment-action .ant-upload-list-item-info .ant-upload-list-item-name {
  width: 125px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.admin-product-inventory__adjustment-action .ant-upload-list-text .ant-upload-list-item-card-actions {
  position: relative;
  bottom: 6px;
}

.admin-product-inventory__adjustment-action .ant-upload-list-item-info .anticon-paper-clip {
  display: none;
}

.admin-product-inventory__header__product-info img {
  width: 100%;
  height: 100%;
}

.inventoryPagination .ant-pagination-disabled .ant-pagination-item-link {
  color: #5e6871;
}

.inventoryPagination .ant-pagination-item-active {
  background: #8c8c8c;
  border-radius: 2px;
  border: none;
}

.inventoryPagination .ant-pagination-item.ant-pagination-item-active a {
  color: white;
}

.inventoryPagination .ant-pagination-item a {
  font-family: Proxima Nova;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #262626;
}

#productInventoryTable .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #ffffff !important;
  border: 1px solid #bfbfbf !important;
}

#productInventoryTable .ant-checkbox-checked .ant-checkbox-inner::after {
  border-color: #595959;
}

.admin-product-inventory__adjustment-action__content-input .ant-input-number-input {
  padding: 0px;
}

.admin-product-inventory__adjustment-action__content-input .ant-input-number-input::-webkit-input-placeholder {
  color: white;
  font-size: 14px;
  line-height: 22px;
}

.admin-product-inventory__adjustment-action__content-input.ant-input-number {
  color: white;
}
</style>
